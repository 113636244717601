/** @format */

import React, { useState } from "react";
import { Link } from "react-scroll";

import GFLogo from "../../assets/Svg/Logo.svg";
import { ReactComponent as MenuIcon } from "../../assets/Svg/MenuIcon.svg";
import { ReactComponent as MenuCloseIcon } from "../../assets/Svg/MenuCloseIcon.svg";
import { Drawer } from "@mui/material";
import Paper from "@mui/material/Paper";

function Navbar() {
  const [showDrawer, setshowDrawer] = useState(false);

  const navbarItems = [
    {
      title: "Home",
      section: "hero",
    },
    {
      title: "Our products",
      section: "works",
    },
    {
      title: "Services",
      section: "services",
    },
    {
      title: "Contact us",
      section: "contactus",
    },
  ];
  const innerWidth = window.innerWidth;

  const toggleDrawer = () => setshowDrawer(!showDrawer);

  return (
    <>
      <header
        id="header"
        className="fixed top-0 w-[100%] z-10 pb-4 bg-[#131313]"
      >
        <div className="bg-[#131313] xl:px-32 max-xl:px-12">
          {innerWidth > 768 && (
            <div className="flex items-center justify-between pt-7">
              <a href="./">
                <img src={GFLogo} className="h-auto w-48" />
              </a>
              <nav className="flex lg:gap-16 gap-5 ">
                {navbarItems.map((item, index) => {
                  return (
                    <Link
                      key={index}
                      to={item.section}
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="text-[#ffffff] text-xl font-medium hover:text-[#c6c6c6] tracking-wider cursor-pointer"
                      style={{
                        textDecoration: "none",
                        color: "#ffffff",
                      }}
                      onClick={toggleDrawer}
                    >
                      {item.title}
                    </Link>
                  );
                })}
                <a
                  href="https://studios.greyfeathers.io/"
                  target="_blank"
                  className="text-xl text-blue-600 hover:text-blue-500"
                >
                  Greyfeathers Studios
                </a>
              </nav>
            </div>
          )}
        </div>
        {/* mobile menu */}
        {window.innerWidth <= 769 && (
          <>
            <div className="flex justify-between items-center fixed top-0 w-[100%] z-10 px-3 py-4 bg-[#131313]">
              <a href="./">
                <img src={GFLogo} className="h-10 w-40" alt="Your Logo" />
              </a>
              {showDrawer ? (
                <MenuCloseIcon className="w-10" onClick={toggleDrawer} />
              ) : (
                <MenuIcon className="w-10" onClick={toggleDrawer} />
              )}
            </div>
            <Drawer
              BackdropProps={{ invisible: true }}
              open={showDrawer}
              onClose={toggleDrawer}
              anchor="right"
              PaperProps={{
                style: {
                  width: "100%",
                  maxWidth: "100%",
                  height: "100vh",
                  marginTop: "70px",
                  boxShadow: "none",
                },
              }}
            >
              <Paper style={{ backgroundColor: "#131313" }} square>
                <div className="w-full h-full">
                  <nav className="flex flex-col items-center pt-24 h-screen gap-y-14 ">
                    {navbarItems.map((item, index) => (
                      <Link
                        key={index}
                        to={item.section}
                        spy={true}
                        smooth={true}
                        duration={500}
                        className="text-white items-center font-medium text-4xl tracking-wide  hover:text-[#ffffff]"
                        style={{ textDecoration: "none" }}
                        onClick={toggleDrawer}
                      >
                        {item.title}
                      </Link>
                    ))}
                  </nav>
                </div>
              </Paper>
            </Drawer>
          </>
        )}
      </header>
    </>
  );
}

export default Navbar;
