/** @format */

import React, { useEffect } from "react";
import CopyRights from "../../components/CopyRights";
import Footer from "../Footer";

export default function RefundPolicy() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <div style={{ padding: "5rem" }}>
        <div style={{ textAlign: "center", fontSize: "2rem", color: "#000" }}>
          Refund policy
        </div>
        <div
          style={{ marginTop: 20, fontSize: "1.3rem", lineHeight: "2.3rem" }}
        >
          No Refunds: Once you have made a payment or subscribed to our web
          application, we do not offer refunds under any circumstances. Trial
          Period: We may provide a trial period or a demo version of our web
          application to allow you to assess its features and functionalities.
          During this trial period, you can evaluate whether our web application
          meets your requirements before making a purchase or subscribing. Once
          the trial period is over, any payment made is considered final, and no
          refunds will be issued. Understanding the Features: We encourage you
          to thoroughly understand the features, functionalities, and
          limitations of our web application before committing to a purchase or
          subscription. We provide detailed documentation, FAQs, and customer
          support to assist you in making an informed decision. Technical
          Support: We are committed to providing excellent technical support to
          ensure that any issues or queries you may encounter are promptly
          addressed. In the event of technical difficulties, please reach out to
          our support team, and we will make every effort to assist you. Changes
          and Updates: We may introduce changes, updates, or enhancements to our
          web application from time to time. These changes may include adding or
          removing features or modifying the pricing structure. However,
          regardless of such changes, the refund policy remains in effect, and
          refunds will not be issued. Unauthorized Use: In case of any
          unauthorized use or breach of our terms of service, we reserve the
          right to suspend or terminate your access to our web application
          without providing a refund.
        </div>
      </div>
      <Footer />
      <CopyRights />
    </div>
  );
}
