import React from "react";
import Team1 from "../../../src/assets/images/team/1.jpg";
import TeamMemberCard from "../../components/TeamMemberCard";

function Team() {
  return (
    <section id="team" className="team with-line">
      <div className="container">
        {/* section header */}
        <div className="section-header">
          <span className="back-text">Member</span>
          <h2>Our Team</h2>
          <span className="line" />
        </div>
        <div className="row">
          <TeamMemberCard name="Team Member Name" image={Team1} position={"Developer"} />
          <TeamMemberCard name="Team Member Name" image={Team1} position={"Developer"} />
          <TeamMemberCard name="Team Member Name" image={Team1} position={"Developer"} />
          <TeamMemberCard name="Team Member Name" image={Team1} position={"Developer"} />
        </div>
      </div>
    </section>
  );
}

export default Team;
