/** @format */

import React from "react";

function CopyRights() {
  return (
    <div className="bg-[#ffffff] max-xl:px-16 max-lg:px-7 px-28 max-sm:px-7 max-sm:py-5 py-5">
      <div className="">
        <span className="text-[#131313] text-lg max-sm:text-sm text-left ">
          ©2022 Greyfeathers (OPC) Pvt Ltd. All rights reserved.
        </span>
      </div>
    </div>
  );
}

export default CopyRights;
