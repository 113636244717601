/** @format */

import * as React from "react";
import Select from "react-select";

export default function CustomSelect({ value, options, handleChange, label, ...props }) {
   return (
      <Select
         options={options.map((option) => ({
            value: option,
            label: option,
         }))}
         styles={{
            control: (provided, state) => ({
               ...provided,
               border: "4px solid #E5E7EB",
               borderRadius: "12px",
               outline: "none",
               padding: "3px 2px",
               boxShadow: "none",
               "&:hover": {
                  border: "4px solid #E5E5E5",
               },
            }),
            placeholder: (provided, state) => ({
               ...provided,
               fontSize: "16px",
               lineHeight: "24px",
               fontWeight: "bold",
               fontFamily: "Manrope,sans-serif",
            }),
            singleValue: (provided, state) => ({
               ...provided,
               color: "#000",
               fontSize: "16px",
               lineHeight: "24px",
               fontWeight: "bold",
               fontFamily: "Manrope,sans-serif",
            }),
            input: (provided, state) => ({
               ...provided,
               color: "rgb(107 114 128 )",
            }),
            option: (provided, state) => ({
               ...provided,
               fontFamily: "Manrope,sans-serif",
               fontSize: "16px",
            }),
            indicatorSeparator: (provided, state) => ({
               ...provided,
               display: "none",
            }),
         }}
         onChange={(val) => handleChange({ target: { name: props.name, value: val.value } })}
         {...props}
      />
   );
}
