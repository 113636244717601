/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/Svg/footerLogo.svg";
import PhoneIcon from "../../assets/Svg/phone.svg";
import EmailIcon from "../../assets/Svg/mail.svg";

function Footer() {
	const navigate = useNavigate();

	return (
		<section
			id="footer"
			className="bg-[#ffffff] text-[#131313] px-28 max-xl:px-16 max-sm:px-7 max-lg:px-7 max-lg:py-10 max-sm:pt-1 lg:py-10">
			<div className="flex justify-between lg:flex-row flex-col lg:gap-y-0 gap-y-14 ">
				<div className="flex flex-col lg:gap-y-20 gap-y-7 lg:order-first order-last">
					<img
						className="w-44 h-9 cursor-pointer"
						src={Logo}
						alt="logo"
					/>
					<p className="text-xl max-sm:text-lg font-normal tracking-normal">
						3/236-A, Nilla Street, Krishna Nagar,
						<br /> Tirupallai, Madurai, Tamilnadu,
						<br /> India - 625014
					</p>
				</div>
				<div className="flex lg:flex-row flex-col xl:gap-x-40 lg:gap-x-10 max-xl:gap-x-4  font-normal tracking-tight lg:gap-y-0 gap-y-10 ">
					<div className="flex flex-col gap-y-6 max-lg:gap-y-3">
						<h2 className="xl:text-3xl lg:text-2xl m-0 mb-3 font-extrabold">LEGAL POLICIES</h2>
						<a
							href="https://cumbersome-petalite-733.notion.site/Terms-and-conditions-38a4c418137f4354862210f3721e1723?pvs=4"
							target="_blank"
							className="cursor-pointer tracking-normal hover:text-[#505050] text-xl max-sm:text-lg ">
							Terms & Conditions
						</a>
						<a
							href="https://cumbersome-petalite-733.notion.site/Privacy-policy-45d0ae3413774928b89bf33a019fc2d9?pvs=4"
							target="_blank"
							className="cursor-pointer tracking-normal hover:text-[#505050] text-xl max-sm:text-lg">
							Privacy policy
						</a>
						{/* <p
							className="cursor-pointer tracking-normal hover:text-[#505050] text-xl max-sm:text-lg"
							onClick={() => {
								navigate("/refund-policy");
							}}>
							Refund policy
						</p> */}
					</div>
					<div className="flex flex-col gap-y-6 tracking-tight max-lg:gap-y-3">
						<h2 className="xl:text-3xl lg:text-2xl m-0 mb-3 font-extrabold">CONTACT US</h2>
						<div
							className="cursor-pointer tracking-normal flex gap-2 hover:text-[#505050]"
							onClick={() => (window.location.href = "tel:+917418590412")}>
							<img src={PhoneIcon} />
							<span className="text-xl max-sm:text-lg">+91 74185 90412</span>
						</div>
						<p
							className="cursor-pointer tracking-normal flex gap-3 hover:text-[#505050]"
							onClick={() => (window.location.href = "mailto:support@greyfeathers.in")}>
							<img src={EmailIcon} />
							<span className="text-xl max-sm:text-lg">support@greyfeathers.in</span>
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Footer;
