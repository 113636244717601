/** @format */

import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import "./App.css";
import CopyRights from "./components/CopyRights";
import Services from "./pages/Services";
import Team from "./pages/Team";
import Works from "./pages/Works";
import Pricing from "./pages/Pricing";
import Blogs from "./pages/Blogs";
import Footer from "./pages/Footer";
import { useEffect } from "react";
import WOW from "wow.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import RefundPolicy from "./pages/RefundPolicy";

function App() {
  var wow = new WOW({
    boxClass: "wow", // animated element css class (default is wow)
    animateClass: "animated", // animation css class (default is animated)
    offset: 0, // distance to the element when triggering the animation (default is 0)
    mobile: true, // trigger animations on mobile devices (default is true)
    live: true, // act on asynchronously loaded content (default is true)
    callback: function (box) {
      // the callback is fired every time an animation is started
      // the argument that is passed in is the DOM node being animated
    },
    scrollContainer: null, // optional scroll container selector, otherwise use window,
    resetAnimation: true, // reset animation on end (default is true)
  });

  useEffect(() => {
    wow.init();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
