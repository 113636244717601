/** @format */

import React from "react";
import Navbar from "../../components/Navbar";
import Home from "../Home";
import Services from "../Services";
import Works from "../Works";
import Footer from "../Footer";
import CopyRights from "../../components/CopyRights";
import ContactUs from "../ContactUs/ContactUs";
import { Toaster } from "react-hot-toast";

export default function Main() {
	return (
		<>
			<Toaster />
			<Navbar />
			<Home />
			<Works />
			<Services />
			{/* <Team /> */}
			{/* <Pricing /> */}
			{/* <Blogs /> */}
			<ContactUs />
			<Footer />
			<CopyRights />
		</>
	);
}
