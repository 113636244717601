/** @format */

import React from "react";
import WebIcon from "../../assets/Svg/WebIcon.svg";
import appDevIcon from "../../assets/Svg/appDevIcon.svg";
import designIcon from "../../assets/Svg/designIcon.svg";
import AIIcon from "../../assets/Svg/AIIcon.svg";
import "./Services.css";

function Services() {
	const servicesData = [
		{
			icon: WebIcon,
			title: "Web Development",
			description: "Building innovative, user-friendly websites for your digital success.",
		},
		{
			icon: appDevIcon,
			title: "App Development",
			description: "Developing innovative apps to revolutionize your digital experience.",
		},
		{
			icon: AIIcon,
			title: "AI/ML Applications",
			description: "Innovative AI/ML application development for cutting-edge technological solutions.",
		},
		{
			icon: designIcon,
			title: "UX/UI Design",
			description: "Building intuitive apps with stellar design for an unmatched user experience.",
		},
	];
	return (
		<section
			id="services"
			className="bg-[#131313] p-24 max-sm:px-3">
			<div className="flex xl:flex-row flex-col justify-center items-center gap-20">
				<div className="text-[#ffffff] max-sm:px-2">
					<h2 className="text-[#ffffff] sm:text-7xl text-4xl font-extrabold custom-align">WHAT WE DO</h2>
					<p className="sm:text-2xl text-xl font-normal sm:text-left custom-align mt-6">Creating software designed for enduring performance and expansive scalability.</p>
				</div>
				<div className="flex flex-col gap-y-10 sm:p-0 p-3">
					{servicesData.map((item, index) => (
						<div
							key={index}
							className="bg-[#ffffff] text-[#131313] flex flex-col sm:flex-row items-center sm:gap-12 gap-2 rounded-3xl lg:p-12 custom-padding ">
							<img
								className="sm:w-auto w-10"
								src={item.icon}
								alt="icon"
							/>
							<div className="flex flex-col sm:gap-y-4 gap-y-2">
								<p className="sm:text-3xl text-xl tracking-tight font-extrabold custom-align">{item.title}</p>
								<p className="sm:text-xl text-base font-medium tracking-tight custom-align">{item.description}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default Services;
