/** @format */

import React from "react";
import { ReactComponent as RightArrow } from "../../assets/Svg/rightarrow.svg";

function Home() {
   const innerWidth = window.innerWidth;

   return (
      <section
         id="hero"
         className="flex items-center justify-center bg-[#131313] w-[100%] h-screen p-0">
         <div className=" lg:w-[70%] w-[100%] flex flex-col items-center">
            <h1 className="text-[#ffffff] font-extrabold m-0 text-center 2xl:text-8xl lg:text-7xl text-5xl max-sm:px-1 max-sm:tracking-tighter">
               BUILDING{innerWidth <= 500 && <br />} PRODUCTS THAT <br />
               MAKE A DIFFERENCE
            </h1>
            <p className="xl:text-3xl font-manrope font-extralight md:text-2xl text-sm text-center text-[#ffffff] mt-4 opacity-60 px-1">Building experiences, one product at a time</p>
            <a
               href="#works"
               className="transition no-underline duration-300 ease-in-out flex items-center gap-1 bg-[#ffffff] focus:outline-0 text-[black] font-semibold rounded-1.5rem mt-12 lg:text-2xl lg:p-6 sm:p-4 max-sm:p-3 text-sm hover:no-underline hover:bg-[#d4d4d4]">
               Explore Products <RightArrow className={`lg:w-7 w-4 ${innerWidth > 1024 && "mt-1"}`} />
            </a>
         </div>
      </section>
   );
}

export default Home;
