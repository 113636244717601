import React, { useState } from "react";
import CustomSelect from "../../components/CustomSelect";
import { toast } from "react-hot-toast";


export default function ContactUs() {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		service: "",
		description: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const submitForm = async () => {
		const { name, email, service, description } = formData;
		if (name.length > 0 && email.length > 0 && service.length > 0 && description.length > 0) {
			// const emailContent = `Hi,\n\nName: ${name}\nEmail: ${email}\n\nService required: ${service}\n\nDescription: ${description}\n\n`;
			// window.location.href = `mailto:rithikraju65@mail.com?subject=A new proposal to grey feathers - GOT AN IDEA, LET'S TALK&body=${encodeURIComponent(emailContent)}`;
			const URL = process.env.REACT_APP_DEV_URL;
			const response = await fetch(`${URL}/enquiry/greyfeathers`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(formData),
			});
			const res = await response.json();
			if (res) {
				setFormData({
					name: "",
					email: "",
					service: "",
					description: "",
				});
				toast.success("Thank you for your interest in working with us. We'll get back to you soon!");
			}
		} else {
			alert("Please fill in all required fields.");
		}
	};

	const options = ["Web Development", "App Development", "UI/UX Design", "AI/ML Applications"];

	return (
		<section
			id="contactus"
			className="py-36 max-lg:py-24 lg:pt-40">
			<div className="grid grid-cols-2 max-lg:grid-cols-1 md:px-24 sm:p-4 max-sm:p-4 gap-x-10 gap-y-10">
				<div className="grid place-items-center">
					<div>
						<h1 className="text-[#131313] sm:text-7xl text-4xl font-extrabold custom-align">
							GOT AN IDEA, <br />
							LET'S TALK
						</h1>
						<p className="sm:text-2xl text-[#131313] max-sm:tracking-normal text-xl font-normal sm:text-left custom-align mt-6">Let us know what you have in mind and we'll get back to you.</p>
					</div>
				</div>
				<div>
					<div className="relative flex flex-col text-xl ">
						<label
							htmlFor="Name"
							className="max-sm:text-lg flex flex-col text-[#131313] font-medium">
							Name*
							<input
								type="text "
								id="Name"
								name="name"
								className="rounded-xl border-4 border-[#E5E7EB] sm:p-3 p-2 outline-none mt-3 text-gray-500 focus:text-[#131313] "
								value={formData.name}
								onChange={handleChange}
								required
							/>
						</label>

						<label
							htmlFor="Email"
							className="max-sm:text-lg flex flex-col text-[#131313] font-medium mt-3">
							Email address*
							<input
								type="text"
								id="Email"
								className="text-lg rounded-xl border-4 border-[#E5E7EB]  sm:p-3 p-2 outline-none mt-3 text-gray-500 focus:text-[#131313]"
								name="email"
								value={formData.email}
								onChange={handleChange}
								required
							/>
						</label>

						<label
							htmlFor="selectChoice"
							className="max-sm:text-lg flex flex-col text-[#131313] font-medium mt-3">
							Service required*
						</label>

						<CustomSelect
							value={formData.service}
							name="service"
							options={options}
							handleChange={handleChange}
							placeholder="Please select a service"
						/>

						<label
							htmlFor="requirementDescription"
							className="max-sm:text-lg flex flex-col text-[#131313] font-medium mt-4">
							Describe your requirement*
						</label>
						<textarea
							id="requirementDescription"
							name="description"
							className="rounded-xl text-xl border-4 border-[#E5E7EB] sm:p-3 p-2 outline-none mt-2 mb-4 text-gray-500 focus:text-[#131313]"
							value={formData.description}
							onChange={handleChange}
							rows={4}
							required></textarea>

						<button
							className="bg-[#131313] text-2xl max-sm:text-xl text-[#ffffff] p-3 rounded-1.5rem sm:mt-5 mt-3"
							onClick={submitForm}>
							Submit
						</button>
					</div>
				</div>
			</div>
		</section>
	);
}
