/** @format */

import React from "react";
import TBMIcon from "../../assets/Icons/ProductIcon/TBMLogo.png";
import DocpodIcon from "../../assets/Icons/ProductIcon/DocpodLogo.png";
import PixelGenIcon from "../../assets/Icons/ProductIcon/PixelGenLogo.png";
import YABAIcon from "../../assets/Icons/ProductIcon/YABALogo.png";
import TripExpensorIcon from "../../assets/Icons/ProductIcon/TripExpenserLogo.png";
import ProfileIcon from "../../assets/Icons/ProductIcon/ProfilePotLogo.png";
import ImagineInteriorIcon from "../../assets/Icons/ProductIcon/ImagineInteriorLogo.png";
import { ReactComponent as SideArrow } from "../../assets/Svg/ProductBtnArrowIcon.svg";

function Works() {
   const openNewTab = (url) => {
      const newWindow = window.open(url, "_blank");
      if (newWindow) newWindow.opener = null;
   };
   const productDetails = [
      {
         id: 1,
         img: TBMIcon,
         name: "The Beautiful Menu",
         description: "Give your customers a fantastic dining experience by letting your  menu do the talking.",
         link: "https://www.thebeautifulmenu.com/",
      },
      {
         id: 2,
         img: DocpodIcon,
         name: "Docpod",
         description: "Simplify your life with our comprehensive document management solution.",
         link: "https://play.google.com/store/apps/details?id=com.docpod",
      },
      {
         id: 3,
         img: PixelGenIcon,
         name: "PixelGen",
         description: "Say hello to stunning imagery where words transform into captivating visuals through AI",
         link: "https://www.pixelgen.art/",
      },
      {
         id: 4,
         img: TripExpensorIcon,
         name: "Trip expensor",
         description: "Empowers you to make informed decisions to keep your trip well within budget.",
         upcoming: false,
         link: "https://play.google.com/store/apps/details?id=com.react_native_expensor_app",
      },
      {
         id: 4,
         img: ProfileIcon,
         name: "Profile pot",
         description: "A better and cleaner way to express your professional self.",
         upcoming: false,
         link: "https://profilepot.me/",
      },
      {
         id: 5,
         img: ImagineInteriorIcon,
         name: "Imagine Interior",
         description: "Imagine your interior with the power of AI.",
         upcoming: false,
         link: "https://interior.pixelgen.art/",
      },
      {
         id: 6,
         img: YABAIcon,
         name: "YABA",
         description: "Unlock a world of smarter budgeting and stress-free savings.",
         upcoming: true,
      },
   ];

   const cardCalucation = (index, length) => {
      return index === length - 1 || index === length - 2;
   };

   return (
      <section
         id="works"
         className="lg:p-14 lg:py-20 py-24">
         <h2 className=" sm:text-6xl text-4xl font-bold text-center pb-14 tracking-tighter">OUR PRODUCTS</h2>
         {/* <div className=" grid lg:grid-cols-3 sm:grid-cols-2 place-items-center gap-y-20">
            {productDetails.map((items, index) => (
               <div
                  key={index}
                  className="w-[80%] text-[#131313] bg-[#EDEFEA] text-center sm:p-3 !pb-5 p-2.5 rounded-1.5rem flex flex-col gap-y-4">
                  <div className="bg-[#ffffff] h-[15rem] rounded-1.5rem  overflow-hidden grid place-items-center relative">
                     <img
                        className={`h-[100%] w-[100%]`}
                        src={items.img}
                        alt="Product Images"
                     />
                     {items?.upcoming && (
                        <span className="bg-[#131313] hover:bg-[#2e2e2e] cursor-pointer text-[#ffffff] sm:text-xl text-sm  rounded-1.5rem sm:px-4 sm:py-2 px-3 py-2 absolute top-2.5 right-2.5">
                           Upcoming
                        </span>
                     )}
                  </div>
                  <p className="font-bold sm:text-3xl text-2xl">{items.name}</p>
                  <p className="sm:text-xl text-sm font-medium">{items.description}</p>
                  {items?.upcoming ? null : (
                     <button
                        onClick={items?.upcoming ? null : () => openNewTab(items.link)}
                        className="transition duration-300 ease-in-out flex items-center justify-center  focus:outline-0  sm:w-48 text-sm sm:text-xl w-36 max-sm:p-2 sm:p-4 mx-auto mt-auto rounded-2xl text-[#ffffff] bg-[#131313] font-semibold hover:bg-[#2e2e2e]">
                        View Product
                        <SideArrow className="m-1" />
                     </button>
                  )}
               </div>
            ))}
         </div> */}
         <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 2xl:gap-y-[5rem] gap-y-[4rem] place-items-center">
            {productDetails.map((items, index) => (
               <div className="flex flex-col gap-2 sm:h-[37rem] h-[28rem] justify-between 2xl:w-[70%] xl:w-[85%] md:w-[75%] w-[80%]  p-[1rem] bg-[#EDEFEA] text-[#000] rounded-3xl text-center">
                  <div className={`relative sm:h-[20rem] h-[14rem] ${items.upcoming && "!h-[25rem]"}`}>
                     <img
                        src={items.img}
                        className="rounded-[1.8rem] w-full h-full"
                     />
                     {items?.upcoming && (
                        <span className="bg-[#131313] hover:bg-[#2e2e2e] cursor-pointer text-[#ffffff] sm:text-xl text-sm  rounded-1.5rem sm:px-4 sm:py-2 px-3 py-2 absolute top-2.5 right-2.5">
                           Upcoming
                        </span>
                     )}
                  </div>
                  <p className="font-semibold 2xl:text-[2rem] sm:text-[1.8rem] text-[1.6rem]">{items.name}</p>
                  <p className={`2xl:text-[1.1rem] text-[1rem] font-medium ${items.upcoming && "pb-[1rem]"}`}>{items.description}</p>
                  {!items.upcoming && (
                     <button
                        onClick={items?.upcoming ? null : () => openNewTab(items.link)}
                        className="bg-[#000] font-semibold max-w-fit rounded-xl mx-auto px-[1rem] py-[0.8rem] text-white flex items-center text-[1rem]">
                        View Product
                        <SideArrow className="m-1" />
                     </button>
                  )}
               </div>
            ))}
         </div>
      </section>
   );
}

export default Works;
